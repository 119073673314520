<template>
  <div class="login-container" @click="loginStyle = false">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">
      <div class="title-container">
        <h2 class="title">智能库房云监测预警平台</h2>
      </div>

      <div v-if="loginStyle" style="position: absolute;left: 0px;top: 0px;">
        <img :src="'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+ticket" style="width: 388px;">
      </div>
      <div @click="qrcode" style="width: 25%;height: 25%;cursor: pointer;position: absolute;right: 0px;top: 0px"></div>

      <el-form-item v-if="!loginStyle"  prop="username">
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="用户名"
          name="loginForm.username"
          type="text"
          tabindex="2"
          autocomplete="on"
        />
      </el-form-item>

      <el-tooltip v-if="!loginStyle"  v-model="capsTooltip" content="大写锁定" placement="right" manual>
        <el-form-item prop="secret">
          <el-input
            key="secret"
            v-model="loginForm.secret"
            type="password"
            placeholder="密码"
            name="secret"
            tabindex="3"
            autocomplete="on"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
        </el-form-item>
      </el-tooltip>

      <el-button v-if="!loginStyle"  :loading="loading" type="primary" class='login-button' @click.native.prevent="handleLogin">登&nbsp;&nbsp;&nbsp;&nbsp;录</el-button>

    </el-form>



  </div>
</template>

<script>

import {login} from '@/api/user'
import md5 from 'js-md5';
// import {Encrypt} from '@/utils/secert'
import {getLoginQrcode} from '@/api/weChat'
import ReconnectingWebSocket from '@/utils/reconnecting-websocket'
import {sm2} from 'sm-crypto'

export default {
  name: 'Login',
  data() {
    return {
      socket:null,
      loginStyle: false,
      ticket:'',
      loginForm: {
        systerm:'/dashboard',
        username:'',
        secret:'',
      },
      options: [{
        key: '/dashboard',
        name: '大屏系统'
      }, {
        key: '/business',
        name: '智能库房云平台'
      }],
      loginRules: {
        username: [
          { required: true, message: '请输入登录用户名', trigger: 'blur' },
          // { min: 1, max: 20, message: '用户名长度 1 到 20 个字符', trigger: 'blur' }
        ],
        secret: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          // { min: 3, max: 10, message: '密码长度 3 到 10 个字符', trigger: 'blur' }
        ]
      },
      capsTooltip: false,
      loading: false,
      licenceRules:{
        unitName:[
          { required: true, message: '单位名称不能为空', trigger: 'blur' }
        ],
        licence:[
          { required: true, message: '序列号不能为空', trigger: 'blur' },
        ]
      }
    }
  },
  mounted() {
    var href = window.location.href;
    if(href.indexOf("template_id=b0758U9UgK4gEJNQbUPzBnuxihnNKHF_iCjck_47Ol4") != -1){
      let action;
      if(href.indexOf("action=confirm") != -1) {
        action = 'confirm'
      }else {
        action = 'cancel'
      }
      this.$router.push({path:'/redirect',query: {action:action}})
    }else {
      this.loginStyle = false
      this.focus();
    }
  },
  methods: {
    initSocket(data) {
      if(typeof(WebSocket) === "undefined"){
        console.log("您的浏览器不支持socket")
      }else{
        // 实例化socket
        let url = location.protocol === 'https:' ? 'wss' : 'ws' + "://"+location.host+"/socket/login.ws/"+data
        this.socket = new ReconnectingWebSocket(url)
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket错误信息
        this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage
        //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
        window.onbeforeunload = () => {
          if (this.socket && this.socket.readyState != 2 && this.socket.readyState != 3) {
            this.socket.close()
          }
        }
      }
    },
    open() {
      console.log("socket connected")
      // this.updateLibraryData()
      // // w  ebsocket 开启后启动定时器，并开始更新数据
      // if(typeof this.timer == "undefined")
      //   this.initTimer()
    },
    error() {
      console.log("connect error")
    },
    getMessage(msg) {
      let data = JSON.parse(msg.data)
      if(data.state == "ok"){
        this.$store.commit('setToken',data.token)
        this.$store.commit('setUser',data.username)
        this.$router.push({
          path: this.loginForm.systerm+'/index'
        })
      }
    },
    qrcode(){
      if(this.loginStyle){
        this.loginStyle = false
        this.socket.close()
      }else{
        getLoginQrcode().then(res => {
          if(res.data && res.data.state=="ok"){
            this.ticket = res.data.data.ticket;
            this.uuid = res.data.data.uuid;
            this.loginStyle = true
            this.initSocket(this.uuid);
          }
        })
      }
    },
    focus(){
      if(this.loginForm.username === ''){
        this.$refs.username.focus()
      }else if(this.loginForm.secret === ''){
        this.$refs.secret.focus()
      }
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },

    handleLogin () {
      let params = Object.assign({}, this.loginForm)
      params.username = this.encrypt(params.username)
      params.secret = this.encrypt(params.secret)
      params.passWord = md5(this.loginForm.secret)
      login(params).then(res => {
        if(res.data && res.data.state=="ok"){
          this.$store.commit('setToken',res.data.token)
          this.$store.commit('setUser',res.data.username)
          this.$router.push({
            path: this.loginForm.systerm+'/index'
          })
        }else{
          this.$message.error(res.data.msg ? res.data.msg :'登录失败')
        }
      })
    },
    encrypt(data) {
      let publicKey = "04272a7c8997d9883078acc011f450f88d54fdbbd6ddab950d7856a565182aad7e0b5da415f50e1d4433c2b633752dfa3bc04de879311091361db05584d972ff06"
      return sm2.doEncrypt(data, publicKey, 0);
    },
  }
}
</script>


<style lang="scss" scoped>

$bg:#283443;
$light_gray:#fff;
$cursor: #fff;

html,body,#app{
  height: 100%;
  margin: 0 0;
}


@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-select,.el-input {
    display: inline-block;
    // height: 47px;
    width: 100%;
    // overflow: hidden;
    padding:0 0;
    margin: 0;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 15px 12px 15px;
      color: #fff;
      margin: 0 0 ;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }
  .licence {
    display: inline-block;
    // height: 47px;
    width: 100%;
    // overflow: hidden;
    padding:0 20px;
    margin: 0;
    textarea {
      -webkit-appearance: none;
      border-radius: 0px;
      margin: 0 0 ;
    }
  }

  .el-input {
    border: 1px solid #117DAF;
    background: #14355F;
    border-radius: 5px;
    color: #FEFEFE;
    line-height: 55px;
    padding:0;
    margin: 0;
  }
  .el-dialog{
    .el-dialog__body {
      text-align: initial;
      padding: 20px 25px 30px;
      overflow: hidden;
      .message {
        text-align: center;
        margin:0 0 25px;
        color:orange;
      }
      form{
        width:75%;float:left;
      }
      .qrCodeDiv{
        width:20%;
        margin-left:3%;
        float:left;
        img{
          width: 100%;
        }
      }
      .is-disabled{
        .el-textarea__inner {
          // background: #14355F !important;
          border-radius: 5px !important;
          color: #aaa !important;
          cursor: text;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;

html{
    height:100%;
}
body{
    height:100%;
    width:100%;
    margin:0;
}
@media screen and (max-width: 400px) {
  .login-container .login-form {
    margin-top: 55% !important;
  }
}

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  background: url('~@/assets/images/login_background.jpg') center 0 no-repeat;
  background-size: cover;

  .login-form {
    position: relative;
    width: 386px;
    height: 386px;
    max-width: 100%;
    margin-top: 15%;
    padding: 50px 20px;
    right: 9%;
    overflow: hidden;
    float: right;
    background: no-repeat url('~@/assets/images/login_border.png');
    background-size: cover;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 30px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
      font-family: '微软雅黑';

      background-image: -webkit-gradient(linear, 0 0, 0 bottom, from(#CAF4FF), to(#09B8FD));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .login-button{
    width:100%;
    margin-top:30px;
    line-height: 30px;

    font-weight: bold;
    font-family: '微软雅黑';
    font-size:20px;
    color:#FEFEFE;
    background:linear-gradient(to bottom, rgba(7,112,176,1),rgba(7,112,176,0.3));
    // background-color: rgb(7,112,176);
    border: 0px solid;
  }
  .login-button:active{
    opacity: 0.8;
  }
}
</style>
